import css from '@emotion/css/macro';
import React, {createContext, useContext, useEffect} from 'react';
import {useAsyncRetry} from 'react-use';
import {AccountService, PasswordLoginCommand, UserDto} from '../api/generated';
import logo from '../assets/ldwf-logo-3c-2018-small.png';
import {DelayedLoadingContainer} from '../components/delayed-loading-container';
import {Form} from '../forms';
import {fieldConfig, getDefaults, input} from '../forms/schema-utils';
import {useProduce} from '../hooks/use-produce';
import {notify, useSubscription} from '../hooks/use-subscription';
import {ValidationError} from '../types/index';
import {CustomerLogin} from '../auth/customer-auth';
import {VendorLogin} from '../auth/vendor-auth';
import {routes} from '../routes/config';
import {
  Dimmer,
  Divider,
  Loader,
  Message,
  Grid,
  Container,
  Header,
  Image,
} from 'semantic-ui-react';
import {Link, useHistory, useLocation} from 'react-router-dom';
import {
  isCustomerVerified,
  isResidencyVerified,
  isVerified,
} from '../utils/auth-helpers';
import useLocallyStoredTableFilters from '../hooks/use-local-storage';
import {mobileRoutes} from '../mobile/routes/config';

export type AuthState = {
  user: UserDto | null;
  errors: ValidationError[];
};

const INITIAL_STATE: AuthState = {
  user: null,
  errors: [],
};

export const AuthContext = createContext<AuthState>(INITIAL_STATE);

const fields = fieldConfig<PasswordLoginCommand>({
  username: input({
    fieldLabel: 'Username',
  }),
  password: input({
    fieldLabel: 'Password',
    inputProps: {
      type: 'password',
    },
  }),
});

const defaultFormState = getDefaults(fields);

export const AuthProvider = (props: any) => {
  const [state, setState] = useProduce<AuthState>(INITIAL_STATE);
  const history = useHistory();
  const {pathname} = useLocation();

  const isMobileUser = pathname === `/${mobileRoutes.create}`;

  const fetchCurrentUser = useAsyncRetry(async () => {
    setState((draft) => {
      draft.errors = [];
    });

    const response = await AccountService.getLoggedInUserDetails();

    setState((draft) => {
      draft.user = response.result ?? null;
      draft.errors = response.validationFailures ?? [];
    });

    let homeRoute = routes.customer.root;
    let isVendor = response.result?.vendorId;
    if (isVendor) {
      homeRoute = routes.vendor.root;
    } else if (response.result?.customerId) {
      homeRoute = routes.customer.dashboard;
    }
    sessionStorage.setItem('homeRoute', homeRoute);

    return response;
  }, [setState]);

  useSubscription('refresh-session', (obj) => {
    fetchCurrentUser.retry();

    if (obj?.isVerifying) {
      sessionStorage.setItem(isVerified, 'true');
    }

    if (obj?.didVerifyResidency) {
      sessionStorage.setItem(isResidencyVerified, 'true');
    }
  });

  const onSubmit = async (values) => {
    const response = await AccountService.login({
      body: values,
    });

    if (response.hasErrors) {
      if (
        response.validationFailures?.some(
          (x) => x.propertyName === 'ExpiredPassword'
        )
      ) {
        const id = response.validationFailures?.find(
          (x) => x.propertyName === 'Id'
        );

        if (id && id.errorMessage) {
          history.push(
            routes.changeExpiredPassword +
              `?id=${encodeURIComponent(id.errorMessage)}&isVendor=0`
          );
        }
      }
      return response;
    }
    notify('refresh-session', undefined);
  };

  const {removeAllSavedFilters} = useLocallyStoredTableFilters('');

  useEffect(() => {
    if (!fetchCurrentUser.value?.result?.id && !fetchCurrentUser.loading) {
      removeAllSavedFilters();
    }
  }, [fetchCurrentUser, removeAllSavedFilters]);

  const DeterminePortal = () => {
    if (pathname.includes('admin')) {
      return (
        <Grid css={styles} verticalAlign="middle">
          <Grid.Column>
            <Container text>
              {state.errors.length > 0 && (
                <Message negative>
                  {state.errors.map((x) => (
                    <p key={x.propertyName}>{x.errorMessage}</p>
                  ))}
                </Message>
              )}
              <Form.Container>
                <Image src={logo} className="logo" />
                <Form
                  initialValues={defaultFormState}
                  onSubmit={onSubmit}
                  render={() => (
                    <>
                      <Form.Section
                        title={
                          <>
                            Louisiana Department of Wildlife & Fisheries
                            <Header.Subheader>
                              Log in to your account
                            </Header.Subheader>
                          </>
                        }
                      >
                        <Form.Input fieldConfig={fields.username} />
                        <Form.Input fieldConfig={fields.password} />
                        <div className="form-footer">
                          <Form.Row proportions={[1, 1]}>
                            <Link
                              className="forgot-password"
                              to={routes.forgotPassword}
                            >
                              Forgot Password?
                            </Link>
                          </Form.Row>
                        </div>
                      </Form.Section>

                      <Divider />
                      <div className="form-actions">
                        <Form.Button type="submit" primary>
                          Log In
                        </Form.Button>
                      </div>
                    </>
                  )}
                ></Form>
              </Form.Container>
            </Container>
          </Grid.Column>
        </Grid>
      );
    } else if (pathname.includes('vendor')) {
      return <VendorLogin fetchCurrentUserRetry={fetchCurrentUser.retry} />;
    } else
      return <CustomerLogin user={state.user} isMobileUser={isMobileUser} />;
  };

  if (fetchCurrentUser.loading) {
    return (
      <div css={styles}>
        <DelayedLoadingContainer delayInMs={1000}>
          <Dimmer active inverted>
            <Loader indeterminate />
          </Dimmer>
        </DelayedLoadingContainer>
      </div>
    );
  }

  const isUserVerified = isCustomerVerified();
  const isInCustomerRoute =
    !pathname.includes('vendor') && !pathname.includes('admin');

  if (isInCustomerRoute) {
    return !state.user || !isUserVerified ? (
      DeterminePortal()
    ) : (
      <AuthContext.Provider value={state} {...props} />
    );
  }

  return !state.user ? (
    DeterminePortal()
  ) : (
    <AuthContext.Provider value={state} {...props} />
  );
};

export function useUser(): UserDto {
  const {user} = useContext(AuthContext);
  if (!user) {
    throw new Error(`useUser must be used within an authenticated app`);
  }
  return user;
}

const styles = css`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #f5f5f5;

  .logo {
    margin: -100px auto 25px;
  }

  .forgot-password {
    margin-left: auto;
  }

  .form-footer {
    .form-field {
      padding-left: 0px !important;
    }
  }
`;
