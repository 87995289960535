import {SkipNavContent} from '@reach/skip-nav';
import React from 'react';
import {useAsync} from 'react-use';
import {IspCustomersService} from './api/generated';
import {ChatWidget} from './components/chat-widget';
import {CustomerRoutes} from './internet-sales-portal/routes';

export const CustomerApp = () => {
  var isChatbotEnabled = useAsync(async () => {
    var {result} = await IspCustomersService.isChatbotEnabled();

    return result;
  });

  return (
    <>
      {isChatbotEnabled.value && <ChatWidget />}

      <SkipNavContent></SkipNavContent>
      <CustomerRoutes />
    </>
  );
};
