import css from '@emotion/css';
import {cx} from 'emotion';
import React, {useMemo} from 'react';
import {useHistory} from 'react-router-dom';
import {useAsyncFn, useMedia} from 'react-use';
import {
  BoatRenewalLookupCommand,
  BoatRenewalService,
} from '../../api/generated';
import {Flex} from '../../components/flex';
import {Form} from '../../forms';
import {fieldConfig, masked, RawFieldConfig} from '../../forms/schema-utils';
import {routes, buildPath} from '../../routes/config';
import {StyledButton} from '../../styled-components/styled-buttons';
import {StyledPageContainer} from '../../styled-page-container';
import {Media, MediaSizes} from '../../styles/breakpoints';
import {notifications} from '../../utils/notification-service';
import {getHomeRoute} from '../../utils/auth-helpers';
import {Divider, Message} from 'semantic-ui-react';
import {Typography} from '../../styled-components/typography';
import {resourceRoutes} from '../../internet-sales-portal/routes/config';

const fields = fieldConfig<BoatRenewalLookupCommand>({
  registrationNumber: masked({
    fieldLabel: 'Registration Number',
    fieldRequired: true,
    inputProps: {
      options: {
        prefix: 'LA ',
        blocks: [7, 2],
        delimiter: '-',
        uppercase: true,
      },
    },
  }),
  hullIdNumber: masked({
    fieldLabel: 'Hull Id (First Four)',
    fieldRequired: true,
    inputProps: {
      placeholder: 'Do not input dashes',
      options: {
        blocks: [4],
        uppercase: true,
      },
    },
  }),
});

export const BoatRenewalLookup = () => {
  const history = useHistory();
  const homeRoute = getHomeRoute();
  const isMobile = useMedia(`(${MediaSizes.MobileMax})`);
  const initialValues = useMemo(
    () => ({
      registrationNumber: '',
      hullIdNumber: '',
    }),
    []
  );

  const [, submitBoatLookup] = useAsyncFn(
    async (values: BoatRenewalLookupCommand) => {
      values.registrationNumber = values.registrationNumber?.replace(
        /[ -]/g,
        ''
      );

      const response = await BoatRenewalService.getBoatRenewal({body: values});

      if (response.hasErrors) {
        response.validationFailures?.forEach((err) => {
          notifications.error(err.errorMessage);
        });
        return response;
      }

      const boatRenewalTransaction = await BoatRenewalService.getBoatRenewalTransactionById(
        {
          boatRenewalId: Number(response.result?.id),
        }
      );

      if (boatRenewalTransaction.hasErrors) {
        boatRenewalTransaction.validationFailures?.forEach((err) => {
          notifications.error(err.errorMessage);
        });
        return boatRenewalTransaction;
      }

      if (boatRenewalTransaction.result) {
        history.replace(
          buildPath(routes.boatRenewal.boatRegistrationReceipt, {
            boatRenewalId: Number(boatRenewalTransaction.result.boatRenewalId),
          }),
          {
            registration: response.result,
          }
        );
      } else {
        history.push(routes.boatRenewal.boatRegistrationConfirmation, {
          registration: response.result,
        });
      }
    }
  );

  return (
    <StyledPageContainer fluid={isMobile} title="Pay Boat Renewal">
      <Flex.Row css={styles} justifyContent="space-between" flexWrap="wrap">
        <Flex.Col>
          <Form
            initialValues={initialValues}
            className={cx(isMobile ? 'ui form mini' : '')}
            onSubmit={submitBoatLookup}
            render={() => (
              <>
                <FormFields isMobile={isMobile} fields={fields} />
                <Flex.Row className="form-actions">
                  <StyledButton
                    size={isMobile ? 'sm' : 'md'}
                    padded={!isMobile}
                    type="submit"
                    primary
                  >
                    Submit
                  </StyledButton>
                  <StyledButton
                    padded={!isMobile}
                    size={isMobile ? 'sm' : 'md'}
                    onClick={() =>
                      history.push(homeRoute ?? routes.salesLanding)
                    }
                  >
                    Cancel
                  </StyledButton>
                </Flex.Row>
              </>
            )}
          />
        </Flex.Col>
        <Flex.Col className="boat-renewal-directions">
          <Typography variant="heading1">
            Can't find your boat registration?{' '}
          </Typography>
          <Divider fitted />
          <Message info>
            Information regarding how to Title or Register your boat can be
            found{' '}
            <a
              href={resourceRoutes.externalBoatRenewalInformation}
              rel="noopener noreferrer"
              target="_blank"
            >
              <u>here</u>
            </a>
            .
            <br />
            <br />
            Your Registration Number and Hull ID can be found on your current
            boat registration card. When entering your Registration Number,{' '}
            <b>
              please exclude any dashes, slashes or other special characters.
            </b>{' '}
            If your boat does not have a Hull ID number, please type the word{' '}
            <b>NONE</b> for the Hull ID.
          </Message>
          <Message warning>
            If your registration information is not found, contact LDWF HQ using
            the contact information below.
            <br />
            <br />
            Please keep in mind that boat registrations can be renewed{' '}
            <b>60 days before the expiration date</b>. The expiration date is
            printed on your registration certificate. If you have not received
            your registration certificate and decals within 30 days of applying
            or renewing, please contact LDWF HQ using the contact information
            below.
            <br />
            <br />
            LDWF HQ: <b>(225)-765-2887</b> or <b>(225)-765-2898.</b>
            <br />
            <br />
            LDWF HQ is open Monday - Friday from 8am - 4pm (excluding
            state/federal holidays).
          </Message>
        </Flex.Col>
      </Flex.Row>
    </StyledPageContainer>
  );
};

export const FormFields: React.FC<{
  fields: RawFieldConfig<BoatRenewalLookupCommand>;
  isMobile: boolean;
}> = ({fields}) => {
  return (
    <Form.Section title="Boat Registration Lookup">
      <Form.Row proportions={[1]}>
        <Form.InputMasked fieldConfig={fields.registrationNumber} />
      </Form.Row>
      <Form.Row proportions={[1]}>
        <Form.InputMasked fieldConfig={fields.hullIdNumber} />
      </Form.Row>
    </Form.Section>
  );
};

const styles = css`
  .form-field {
    padding: 0px !important;
  }

  ${Media('DesktopMin')} {
    .boat-renewal-directions {
      max-width: 465px;
      margin-right: 214px;
    }
  }

  ${Media('MobileMax')} {
    .boat-renewal-directions {
      max-width: 465px;
    }
  }
`;
