type REACT_APP_ENVIRONMENT =
  | 'local'
  | 'Dev'
  | 'QA'
  | 'Stage'
  | 'Hotfix'
  | 'Production'
  | 'Production2';

export type Env = {
  isProductionBuild: boolean;
  publicUrl: string;

  name: REACT_APP_ENVIRONMENT;
  buildNumber: string;
  apiBaseUrl?: string;
  host: string;
  appRoot: string;

  googleAnalyticsId?: string;
  googleTagManagerId?: string;
  sentryKey?: string;
  sentryProjectId?: string;
  applicationInsightsKey?: string;

  twilioSID?: string;
  twilioFlowSID?: string;

  twilioCustomerUrl?: string;
  twilioVendorUrl?: string;
  chatBotLogo?: string;

  klaviyoPublicKey?: string;
  klaviyoPrivateKey?: string;
  klaviyoCompanyId?: string;

  elavonConvergeLightboxUrl?: string;
};

const host = `${window.location.protocol}//${window.location.host}`;
const appRoot = `${host}`;

export const Env: Env = {
  isProductionBuild: process.env.NODE_ENV === 'production',
  publicUrl: process.env.PUBLIC_URL,

  name: process.env.REACT_APP_ENVIRONMENT as REACT_APP_ENVIRONMENT,
  buildNumber: process.env.REACT_APP_BUILD_NUMBER || 'local',
  apiBaseUrl: process.env.REACT_APP_API_BASE_URL || '/',

  host,
  appRoot,

  googleAnalyticsId: process.env.REACT_APP_GOOGLE_ANALYTICS_ID,
  googleTagManagerId: process.env.REACT_APP_GOOGLE_TAG_MANAGER,
  sentryKey: process.env.REACT_APP_SENTRY_PUBLIC_KEY,
  sentryProjectId: process.env.REACT_APP_SENTRY_PUBLIC_PROJECT_ID,
  applicationInsightsKey: process.env.REACT_APP_APPLICATION_INSIGHTS_KEY,

  twilioSID: process.env.REACT_APP_TWILIO_CHAT_SID,
  twilioFlowSID: process.env.REACT_APP_TWILIO_CHAT_FLOW_SID,

  twilioCustomerUrl: process.env.REACT_APP_TWILIO_CUSTOMER_URL,
  twilioVendorUrl: process.env.REACT_APP_TWILIO_VENDOR_URL,
  elavonConvergeLightboxUrl: process.env.REACT_APP_ELAVON_CONVERGE_LIGHTBOX_URL,
  chatBotLogo: process.env.REACT_APP_CHAT_BOT_LOGO,

  klaviyoPublicKey: process.env.REACT_APP_KLAVIYO_PUBLIC_KEY,
  klaviyoPrivateKey: process.env.REACT_APP_KLAVIYO_PRIVATE_KEY,
  klaviyoCompanyId: process.env.REACT_APP_KLAVIYO_COMPANY_ID,
};
