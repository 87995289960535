// Enums

export type CsvTypes = keyof typeof CsvTypes;
export const CsvTypes = {Kalkomey: 'Kalkomey', BoatUs: 'BoatUs'} as const;

export type VoidTransactionReasons = keyof typeof VoidTransactionReasons;
export const VoidTransactionReasons = {
  'DID NOT PRINT': 'DidNotPrint',
  'NO PAYMENT RECEIVED': 'NoPaymentReceived',
  'WRONG ITEM PURCHASED': 'WrongItemPurchased',
  OTHER: 'Other',
} as const;

export type TagSpeciesCodes = keyof typeof TagSpeciesCodes;
export const TagSpeciesCodes = {
  TURKEY: 'TK',
  'DEER - EITHER SEX': 'DR',
  'ANTLERED DEER': 'AD',
  'ANTLERLESS DEER': 'NA',
} as const;

export type ContentType = keyof typeof ContentType;
export const ContentType = {
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'Excel',
  'application/pdf': 'Pdf',
  'text/csv': 'Csv',
  'text/html': 'Text',
  'application/json': 'Json',
} as const;

export type FileExtension = keyof typeof FileExtension;
export const FileExtension = {
  xlsx: 'Excel',
  pdf: 'Pdf',
  csv: 'Csv',
  txt: 'Text',
  json: 'Json',
} as const;

export type ReportType = keyof typeof ReportType;
export const ReportType = {
  Excel: 'Excel',
  Pdf: 'Pdf',
  Csv: 'Csv',
  Text: 'Text',
} as const;

export type HarvestReportSpecies = keyof typeof HarvestReportSpecies;
export const HarvestReportSpecies = {DEER: 'Deer', TURKEY: 'Turkey'} as const;

export type FulfillmentType = keyof typeof FulfillmentType;
export const FulfillmentType = {
  PSSLifetimeLicense: 'PSSLifetimeLicense',
  Southwick: 'Southwick',
  HardCard: 'HardCard',
  StateDuckstamp: 'StateDuckstamp',
} as const;

export type AchBalanceTransactionType = keyof typeof AchBalanceTransactionType;
export const AchBalanceTransactionType = {
  ACH: 'Ach',
  'Non ACH': 'NonAch',
  All: 'All',
} as const;

export type LotteryScheduledJobType = keyof typeof LotteryScheduledJobType;
export const LotteryScheduledJobType = {
  'SEND LEFTOVERS AVAILABLE NOTIFICATION': 'SendLeftoversAvailableNotification',
} as const;

export type LotteryNotificationActions = keyof typeof LotteryNotificationActions;
export const LotteryNotificationActions = {
  'AWARD ACCEPTED': 'AwardAccepted',
  'AWARD DECLINED': 'AwardDeclined',
  'HUNT CANCELED': 'HuntCanceled',
  'APPLICATION DISQUALIFIED': 'ApplicationDisqualified',
  'APPLICATION INELIGIBLE': 'ApplicationIneligible',
  'APPLICATION REMOVED': 'ApplicationRemoved',
  'APPLICATION SUBMITTED': 'ApplicationSubmitted',
  'APPLICATION UNSUCCESSFUL': 'ApplicationUnsuccessful',
  'APPLICATION UPDATED': 'ApplicationUpdated',
  'DRAW SUCCESSFUL': 'DrawSuccessful',
  'HUNT SUPPLEMENTAL': 'HuntsSupplemental',
  'LEFTOVERS AVAILABLE': 'LeftoversAvailable',
  'LOTTERY CANCELED': 'LotteryCanceled',
} as const;

export type NotificationSendingOptions = keyof typeof NotificationSendingOptions;
export const NotificationSendingOptions = {
  'ON TRIGGER': 'OnTrigger',
  'ONLY ONCE': 'OnlyOnce',
} as const;

export type LotteryEmailNotificationHandlebarsTokenType = keyof typeof LotteryEmailNotificationHandlebarsTokenType;
export const LotteryEmailNotificationHandlebarsTokenType = {
  'Customer Full Name': 'CustomerFullName',
  'Lottery Name': 'LotteryName',
  'Lottery Year': 'LotteryYear',
  'Leftovers Start Date': 'LeftoversStartDate',
  'Application Start Date': 'ApplicationStartDate',
  'Application End Date': 'ApplicationEndDate',
  'Draw Date': 'DrawDate',
  'Draw Rank': 'DrawRank',
  'Acceptance Deadline': 'AcceptanceDeadline',
  'Lottery Application Instructions': 'LotteryApplicationInstructions',
  'Acceptance Link': 'AcceptanceLink',
  'Group Code': 'GroupCode',
  'Successful Hunt Choice': 'SuccessfulHuntChoice',
  'Selected Hunt Choices': 'SelectedHuntChoices',
  'Hunt Location Phone': 'HuntLocationPhone',
  'Hunt Location Name': 'HuntLocationName',
  'Hunt Start Date': 'HuntStartDate',
  'Hunt End Date': 'HuntEndDate',
  'Hunt Date Range': 'HuntDateRange',
  'Hunt Description': 'HuntDescription',
  'Hunt Street Address 1': 'HuntStreetAddress1',
  'Hunt Street Address 2': 'HuntStreetAddress2',
  'Hunt City': 'HuntCity',
  'Hunt State': 'HuntState',
  'Hunt ZipCode': 'HuntZipCode',
  'Hunt Parish': 'HuntParish',
  'Hunt Directions': 'HuntDirections',
  'Hunt Location Notes': 'HuntLocationNotes',
  'Hunt Additional Information': 'HuntAdditionalInformation',
  'Hunt Location Contact Name': 'HuntLocationContactName',
  'Hunt Location Contact Email': 'HuntLocationContactEmail',
} as const;

export type LotteryApplicationInstructionsHandlebarsTokenType = keyof typeof LotteryApplicationInstructionsHandlebarsTokenType;
export const LotteryApplicationInstructionsHandlebarsTokenType = {
  'Closed Date': 'ClosedDate',
} as const;

export type LotteryStatusCode = keyof typeof LotteryStatusCode;
export const LotteryStatusCode = {
  ACTIVE: 'ACTIVE',
  COMPLETE: 'COMPLETE',
  DRAWN: 'DRAWN',
  CANCELLED: 'CANCELLED',
  EXECUTING: 'EXECUTING',
  FAILED: 'FAILED',
} as const;

export type HuntLocationStatusCode = keyof typeof HuntLocationStatusCode;
export const HuntLocationStatusCode = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
} as const;

export type HuntStatusCode = keyof typeof HuntStatusCode;
export const HuntStatusCode = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
  CANCELLED: 'CANCELLED',
} as const;

export type LotteryFeeType = keyof typeof LotteryFeeType;
export const LotteryFeeType = {
  APPLICATION: 'APPLICATION',
  AWARD: 'AWARD',
} as const;

export type KlaviyoProductType = keyof typeof KlaviyoProductType;
export const KlaviyoProductType = {
  '365 Day': 'LICENSE365',
  '10 Day': 'TENDAY',
  '5 Day': 'FIVEDAY',
  '3 Day': 'THREEDAY',
  '1 Day': 'ONEDAY',
  Other: 'OTHER',
} as const;

export type AddressType = keyof typeof AddressType;
export const AddressType = {
  MAIL: 'MAIL',
  PHYSICAL: 'PHYSICAL',
  SHIPPING: 'SHIPPING',
} as const;

export type BankAccountType = keyof typeof BankAccountType;
export const BankAccountType = {
  CHECKING: 'Checking',
  SAVINGS: 'Savings',
} as const;

export type BillingCycle = keyof typeof BillingCycle;
export const BillingCycle = {WEEKLY: 'Weekly', MONTHLY: 'Monthly'} as const;

export type CertificationSourceIndicator = keyof typeof CertificationSourceIndicator;
export const CertificationSourceIndicator = {
  Unassigned: 'Unassigned',
  Online: 'Online',
  'In Person': 'InPerson',
  Manual: 'Manual',
  Imported: 'Imported',
} as const;

export type CertificationType = keyof typeof CertificationType;
export const CertificationType = {
  COURSE: 'COURSE',
  INSTRUCTOR: 'INSTRUCTOR',
  EXEMPTION: 'EXEMPTION',
  PARTICIPATION: 'PARTICIPATION',
} as const;

export type Claims = keyof typeof Claims;
export const Claims = {
  'Vendor - Manager': 'VendorManager',
  'Vendor - Clerk': 'VendorClerk',
  'License - View License Details': 'LicenseViewLicenseDetails',
  'Customer - Read': 'CustomerRead',
  'Vendor - Read': 'VendorRead',
  'Management - Read': 'ManagementRead',
  'Product - Read': 'ProductRead',
  Reports: 'Reports',
  'Customer - Modify': 'CustomerModify',
  'Vendor - Modify': 'VendorModify',
  'Product - Modify': 'ProductModify',
  'License - Update License Status': 'LicenseUpdateLicenseStatus',
  'Customer - Revocations': 'CustomerRevocations',
  'Customer - Update Status': 'CustomerUpdateStatus',
  'Customer - Issue HC': 'CustomerIssueHc',
  'Customer - Landowner': 'CustomerLandowner',
  'Customer - Merge': 'CustomerMerge',
  'Admin - Sell License': 'AdminSellLicense',
  'Admin - Security': 'AdminSecurity',
  'Accounting - Read': 'AccountingRead',
  'Accounting - Modify': 'AccountingModify',
  'Management - Users': 'ManagementUsers',
  'Customer - SSN': 'CustomerSsn',
  'Vendor - Bank Information Access': 'VendorBankInformationAccess',
  'Vendor - Notifications': 'VendorNotifications',
  'Helpdesk - Harvest Report': 'HelpdeskHarvestReport',
  'LDWF - Admin Override': 'LdwfAdminOverride',
  'Renewal - Read': 'RenewalRead',
  'Events-View Events': 'EventsViewEvents',
  'Events-Review Events': 'EventsReviewEvents',
  'Events-Approve Events': 'EventsApproveEvents',
  Events: 'Events',
  'Reports - Harvest': 'ReportsHarvest',
  'Events-Complete Events': 'EventsCompleteEvents',
  'Events-Cancel Events': 'EventsCancelEvents',
  'Events-Record Results': 'EventsRecordResults',
  'Events-Review Results': 'EventsReviewResults',
  'Admin - Reprints': 'AdminReprints',
  'Events-View Reports': 'EventsViewReports',
  'Lotteries-Config': 'LotteriesConfig',
  'Customer - Edit Certifications': 'CustomerEditCertifications',
  'Customer - Delete Certifications': 'CustomerDeleteCertifications',
  'Customer - Create Certifications': 'CustomerCreateCertifications',
  'Events-Create Events': 'EventsCreateEvents',
  'Events-Modify Events': 'EventsModifyEvents',
  'Events-Grade Students': 'EventsGradeStudents',
  'Events-Add Student': 'EventsAddStudent',
  'Events-Remove Student': 'EventsRemoveStudent',
  'Events-Add Instructor': 'EventsAddInstructor',
  'Events-Remove Instructor': 'EventsRemoveInstructor',
  'Events-Set Primary Instructor': 'EventsSetPrimaryInstructor',
  'Events-View Instructor Types': 'EventsViewInstructorTypes',
  'Events-Create Instructor Types': 'EventsCreateInstructorTypes',
  'Events-Modify Instructor Types': 'EventsModifyInstructorTypes',
  'Events-View Instructors': 'EventsViewInstructors',
  'Events-Create Instructors': 'EventsCreateInstructors',
  'Events-Modify Instructors': 'EventsModifyInstructors',
  'Events-View Event Types': 'EventsViewEventTypes',
  'Events-Create Event Types': 'EventsCreateEventTypes',
  'Events-Modify Event Types': 'EventsModifyEventTypes',
  'Events-View Event Locations': 'EventsViewEventLocations',
  'Events-Create Event Locations': 'EventsCreateEventLocations',
  'Events-Modify Event Locations': 'EventsModifyEventLocations',
  'Events-View Event Areas': 'EventsViewEventAreas',
  'Events-Create Event Areas': 'EventsCreateEventAreas',
  'Events-Modify Event Areas': 'EventsModifyEventAreas',
  'Events-View Class Event Types': 'EventsViewClassEventTypes',
  'Events-Create Class Event Types': 'EventsCreateClassEventTypes',
  'Events-Modify Class Event Types': 'EventsModifyClassEventTypes',
  'Events-View Students': 'EventsViewStudents',
  'Events-Ignore Must Be Instructor': 'EventsIgnoreMustBeInstructor',
  'Events-Email Students': 'EventsEmailStudents',
  'Events-Email Instructors': 'EventsEmailInstructors',
  'Events-View Certification Codes': 'EventsViewCertificationCodes',
  'Reports - License': 'ReportsLicense',
  'Product - Rules': 'ProductRules',
  'Product - Item Fees': 'ProductItemFees',
  'Management - Roles': 'ManagementRoles',
  'Management - Settings': 'ManagementSettings',
  'Management - Harvest Seasons': 'ManagementHarvestSeason',
  'Accounting - Reports': 'AccountingReports',
  'License - Validate Tags': 'LicenseValidateTags',
  'Transactions - View Transactions': 'TransactionsViewTransactions',
  'Transactions - View Transaction Tags': 'TransactionsViewTransactionTags',
  'Corporation - Manage': 'CorporationManage',
  'Login - Admin': 'LoginAdmin',
  'Login - Vendor': 'LoginVendor',
  'Vendor - Corporate': 'VendorCorporate',
  'Reports - Transferals': 'TransferalReports',
  'Lottery Applications-PreferencePoints': 'LotteryApplicationPreferencePoints',
  'Lottery Applications-Statuses': 'LotteryApplicationStatuses',
  'Lottery-Read': 'LotteryRead',
  'Admin - Import Certifications': 'AdminImportCertifications',
  'Admin - Update Vendor Password': 'AdminUpdateVendorPassword',
  'Reports - Vendors': 'ReportsVendors',
  'Reports - Renewals': 'ReportsRenewals',
  Fulfillments: 'Fulfillments',
  'Klaviyo - Product Settings': 'KlaviyoProductSettings',
  'Customer - Edit Preference Points': 'CustomerEditPreferencePoints',
} as const;

export type ColorCodeType = keyof typeof ColorCodeType;
export const ColorCodeType = {E: 'Eye', H: 'Hair', V: 'Vehicle'} as const;

export type CountryCode = keyof typeof CountryCode;
export const CountryCode = {
  'UNITED STATES': 'USA',
  AFGHANISTAN: 'AFG',
  ALBANIA: 'ALB',
  ALGERIA: 'DZA',
  'AMERICAN SAMOA': 'ASM',
  ANDORRA: 'AND',
  ANGOLA: 'AGO',
  ANGUILLA: 'AIA',
  ANTARCTICA: 'ATA',
  'ANTIGUA AND BARBUDA': 'ATG',
  ARGENTINA: 'ARG',
  ARMENIA: 'ARM',
  ARUBA: 'ABW',
  AUSTRALIA: 'AUS',
  AUSTRIA: 'AUT',
  AZERBAIJAN: 'AZE',
  BAHAMAS: 'BHS',
  BAHRAIN: 'BHR',
  BANGLADESH: 'BGD',
  BARBADOS: 'BRB',
  BELARUS: 'BLR',
  BELGIUM: 'BEL',
  BELIZE: 'BLZ',
  BENIN: 'BEN',
  BERMUDA: 'BMU',
  BHUTAN: 'BTN',
  BOLIVIA: 'BOL',
  'BOSNIA AND HERZEGOWINA': 'BIH',
  BOTSWANA: 'BWA',
  'BOUVET ISLAND': 'BVT',
  BRAZIL: 'BRA',
  'BRITISH INDIAN OCEAN TERRITORY': 'IOT',
  'BRUNEI DARUSSALAM': 'BRN',
  BULGARIA: 'BGR',
  'BURKINA FASO': 'BFA',
  BURUNDI: 'BDI',
  CAMBODIA: 'KHM',
  CAMEROON: 'CMR',
  CANADA: 'CAN',
  'CAPE VERDE': 'CPV',
  'CAYMAN ISLANDS': 'CYM',
  'CENTRAL AFRICAN REPUBLIC': 'CAF',
  CHAD: 'TCD',
  CHILE: 'CHL',
  CHINA: 'CHN',
  'CHRISTMAS ISLAND': 'CXR',
  'COCOS (KEELING) ISLANDS': 'CCK',
  COLOMBIA: 'COL',
  COMOROS: 'COM',
  CONGO: 'COG',
  'COOK ISLANDS': 'COK',
  'COSTA RICA': 'CRI',
  "COTE D'IVOIRE": 'CIV',
  CROATIA: 'HRV',
  CUBA: 'CUB',
  CYPRUS: 'CYP',
  'CZECH REPUBLIC': 'CZE',
  DENMARK: 'DNK',
  DJIBOUTI: 'DJI',
  DOMINICA: 'DMA',
  'DOMINICAN REPUBLIC': 'DOM',
  'EAST TIMOR': 'TMP',
  ECUADOR: 'ECU',
  EGYPT: 'EGY',
  'EL SALVADOR': 'SLV',
  'EQUATORIAL GUINEA': 'GNQ',
  ERITREA: 'ERI',
  ESTONIA: 'EST',
  ETHIOPIA: 'ETH',
  'FALKLAND ISLANDS (MALVINAS)': 'FLK',
  'FAROE ISLANDS': 'FRO',
  'FEDERATED STATES OF MICRONESIA': 'FSM',
  FIJI: 'FJI',
  FINLAND: 'FIN',
  FRANCE: 'FRA',
  'FRANCE, METROPOLITAN': 'FXX',
  'FRENCH GUIANA': 'GUF',
  'FRENCH POLYNESIA': 'PYF',
  'FRENCH SOUTHERN TERRITORIES': 'ATF',
  GABON: 'GAB',
  GAMBIA: 'GMB',
  GEORGIA: 'GEO',
  GERMANY: 'DEU',
  GHANA: 'GHA',
  GIBRALTAR: 'GIB',
  GREECE: 'GRC',
  GREENLAND: 'GRL',
  GRENADA: 'GRD',
  GUADELOUPE: 'GLP',
  GUAM: 'GUM',
  GUATEMALA: 'GTM',
  GUINEA: 'GIN',
  'GUINEA-BISSAU': 'GNB',
  GUYANA: 'GUY',
  HAITI: 'HTI',
  'HEARD AND MC DONALD ISLANDS': 'HMD',
  HONDURAS: 'HND',
  'HONG KONG': 'HKG',
  HUNGARY: 'HUN',
  ICELAND: 'ISL',
  INDIA: 'IND',
  INDONESIA: 'IDN',
  IRAN: 'IRN',
  IRAQ: 'IRQ',
  IRELAND: 'IRL',
  ISRAEL: 'ISR',
  ITALY: 'ITA',
  JAMAICA: 'JAM',
  JAPAN: 'JPN',
  JORDAN: 'JOR',
  KAZAKHSTAN: 'KAZ',
  KENYA: 'KEN',
  KIRIBATI: 'KIR',
  'KOREA, DPR': 'PRK',
  'KOREA, REPUBLIC OF': 'KOR',
  KUWAIT: 'KWT',
  KYRGYZSTAN: 'KGZ',
  "LAO PEOPLE'S DEMOCRATIC REPUBLIC": 'LAO',
  LATVIA: 'LVA',
  LEBANON: 'LBN',
  LESOTHO: 'LSO',
  LIBERIA: 'LBR',
  'LIBYAN ARAB JAMAHIRIYA': 'LBY',
  LIECHTENSTEIN: 'LIE',
  LITHUANIA: 'LTU',
  LUXEMBOURG: 'LUX',
  MACAU: 'MAC',
  MACEDONIA: 'MKD',
  MADAGASCAR: 'MDG',
  MALAWI: 'MWI',
  MALAYSIA: 'MYS',
  MALDIVES: 'MDV',
  MALI: 'MLI',
  MALTA: 'MLT',
  'MARSHALL ISLANDS': 'MHL',
  MARTINIQUE: 'MTQ',
  MAURITANIA: 'MRT',
  MAURITIUS: 'MUS',
  MAYOTTE: 'MYT',
  MEXICO: 'MEX',
  'MOLDOVA, REPUBLIC OF': 'MDA',
  MONACO: 'MCO',
  MONGOLIA: 'MNG',
  MONTENEGRO: 'MNE',
  MONTSERRAT: 'MSR',
  MOROCCO: 'MAR',
  MOZAMBIQUE: 'MOZ',
  MYANMAR: 'MMR',
  NAMIBIA: 'NAM',
  NAURU: 'NRU',
  NEPAL: 'NPL',
  NETHERLANDS: 'NLD',
  'NETHERLANDS ANTILLES': 'ANT',
  'NEW CALEDONIA': 'NCL',
  'NEW ZEALAND': 'NZL',
  NICARAGUA: 'NIC',
  NIGER: 'NER',
  NIGERIA: 'NGA',
  NIUE: 'NIU',
  'NORFOLK ISLAND': 'NFK',
  'NORTHERN MARIANA ISLANDS': 'MNP',
  NORWAY: 'NOR',
  OMAN: 'OMN',
  PAKISTAN: 'PAK',
  PALAU: 'PLW',
  PANAMA: 'PAN',
  'PAPUA NEW GUINEA': 'PNG',
  PARAGUAY: 'PRY',
  PERU: 'PER',
  PHILIPPINES: 'PHL',
  PITCAIRN: 'PCN',
  POLAND: 'POL',
  PORTUGAL: 'PRT',
  'PUERTO RICO': 'PRI',
  QATAR: 'QAT',
  REUNION: 'REU',
  ROMANIA: 'ROM',
  'RUSSIAN FEDERATION': 'RUS',
  RWANDA: 'RWA',
  'SAINT KITTS AND NEVIS': 'KNA',
  'SAINT LUCIA': 'LCA',
  'SAINT VINCENT AND THE GRENADINES': 'VCT',
  SAMOA: 'WSM',
  'SAN MARINO': 'SMR',
  'SAO TOME AND PRINCIPE': 'STP',
  'SAUDI ARABIA': 'SAU',
  SENEGAL: 'SEN',
  SERBIA: 'SRB',
  SEYCHELLES: 'SYC',
  'SIERRA LEONE': 'SLE',
  SINGAPORE: 'SGP',
  'SLOVAKIA (SLOVAK REPUBLIC)': 'SVK',
  SLOVENIA: 'SVN',
  'SOLOMON ISLANDS': 'SLB',
  SOMALIA: 'SOM',
  'SOUTH AFRICA': 'ZAF',
  'SOUTH GEORGIA / SANDWICH ISLANDS': 'SGS',
  SPAIN: 'ESP',
  'SR. PIERRE AND MIQUELON': 'SPM',
  'SRI LANKA': 'LKA',
  'ST. HELENA': 'SHN',
  SUDAN: 'SDN',
  SURINAME: 'SUR',
  'SVALBARD AND JAN MAYEN ISLANDS': 'SJM',
  SWAZILAND: 'SWZ',
  SWEDEN: 'SWE',
  SWITZERLAND: 'CHE',
  'SYRIAN ARAB REPUBLIC': 'SYR',
  TAIWAN: 'TWN',
  TAJIKISTAN: 'TJK',
  'TANZANIA, UNITED REPUBLIC OF': 'TZA',
  THAILAND: 'THA',
  TOGO: 'TGO',
  TOKELAU: 'TKL',
  TONGA: 'TON',
  'TRINIDAD AND TOBAGO': 'TTO',
  TUNISIA: 'TUN',
  TURKEY: 'TUR',
  TURKMENISTAN: 'TKM',
  'TURKS AND CAICOS ISLANDS': 'TCA',
  TUVALU: 'TUV',
  UGANDA: 'UGA',
  UKRAINE: 'UKR',
  'UNITED ARAB EMIRATES': 'ARE',
  'UNITED KINGDOM': 'GBR',
  URUGUAY: 'URY',
  'US MINOR OUTLYING ISLANDS': 'UMI',
  UZBEKISTAN: 'UZB',
  VANUATU: 'VUT',
  'VATICAN CITY STATE': 'VAT',
  VENEZUELA: 'VEN',
  VIETNAM: 'VNM',
  'VIRGIN ISLANDS': 'VIR',
  'VIRGIN ISLANDS (BRITISH)': 'VGB',
  'WALLIS ABD FUTUNA ISLANDS': 'WLF',
  'WESTERN SAHARA': 'ESH',
  YEMEN: 'YEM',
  YUGOSLAVIA: 'YUG',
  ZAIRE: 'ZAR',
  ZAMBIA: 'ZMB',
  ZIMBABWE: 'ZWE',
} as const;

export type CourseSystemWebhookSubscription = keyof typeof CourseSystemWebhookSubscription;
export const CourseSystemWebhookSubscription = {
  'enrollment.pass': 'Pass',
  'enrollment.fail': 'Fail',
  'enrollment.start': 'Start',
} as const;

export type CustomerEventState = keyof typeof CustomerEventState;
export const CustomerEventState = {
  INCOMPLETE: 'Incomplete',
  UNDERAGE: 'Underage',
  'NO SHOW': 'NoShow',
  REPEAT: 'Repeat',
  FAILED: 'Failed',
} as const;

export type DistrictCode = keyof typeof DistrictCode;
export const DistrictCode = {
  '1': 'One',
  '2': 'Two',
  '3': 'Three',
  '4': 'Four',
  '5': 'Five',
  '6': 'Six',
  '7': 'Seven',
  '8': 'Eight',
  '9': 'Nine',
} as const;

export type DonationTypeCode = keyof typeof DonationTypeCode;
export const DonationTypeCode = {
  CATALOG: 'Catalog',
  CHECKOUT: 'Checkout',
} as const;

export type EquipmentTypeCode = keyof typeof EquipmentTypeCode;
export const EquipmentTypeCode = {
  WebPOS: 'WebPos',
  POS: 'Pos',
  'Vendor Provided': 'VendorProvided',
} as const;

export type FeeTypeCode = keyof typeof FeeTypeCode;
export const FeeTypeCode = {
  PRODUCT: 'Product',
  COMMISSION: 'Commission',
  'CONVENIENCE FEE': 'ConvenienceFee',
  'CREDIT CARD': 'CreditCard',
} as const;

export type InstructorSummarySortBy = keyof typeof InstructorSummarySortBy;
export const InstructorSummarySortBy = {
  'Hours: Low to High': 'HoursAscending',
  'Hours: High to Low': 'HoursDescending',
} as const;

export type InstructorCertReportSortBy = keyof typeof InstructorCertReportSortBy;
export const InstructorCertReportSortBy = {
  'Hours: Low to High': 'HoursAscending',
  'Hours: High to Low': 'HoursDescending',
} as const;

export type ProductRuleTypeCode = keyof typeof ProductRuleTypeCode;
export const ProductRuleTypeCode = {
  ALLOWS: 'Allows',
  EXCLUDES: 'Excludes',
  UPSELLS: 'Upsells',
  INCLUDES: 'Includes',
} as const;

export type ProductTypeCodeType = keyof typeof ProductTypeCodeType;
export const ProductTypeCodeType = {
  LIFETIME: 'LIFETIME',
  FISHING: 'FISHING',
  HUNTING: 'HUNTING',
  COMBO: 'COMBO',
  'CHECKOUT DONATION': 'CHECKOUT_DONATION',
  'CATALOG DONATION': 'CATALOG_DONATION',
  DUPLICATE: 'DUPLICATE',
  CHARTER: 'CHARTER',
  MILITARY: 'MILITARY',
  DISABLED: 'DISABLED',
  NONE: 'NONE',
  COURSE: 'COURSE',
  MERCHANDISE: 'MERCHANDISE',
  DONATION: 'DONATION',
  'DUPLICATE LICENSE': 'DUPLIATION_LICENSE',
  'ONLINE COURSE': 'ONLINE_COURSE',
  'OTHER SIMPLE PRODUCT': 'OTHER_SIMPLE_PRODUCT',
  'RECREATIONAL LIFETIME LICENSE': 'RECREATIONAL_LIFETIME_LICENSE',
  'RECREATIONAL LICENSE 365': 'RECREATIONAL_LICENSE_365',
  'RECREATIONAL SEASON LICENSE': 'RECREATIONAL_SEASON_LICENSE',
  'RECREATIONAL TEMPORARY LICENSE': 'RECREATIONAL_TEMPORARY_LICENSE',
  'RECREATIONAL TAGS': 'RECREATIONAL_TAGS',
} as const;

export type Roles = keyof typeof Roles;
export const Roles = {
  'LDWF/OTS': 'LdwfOtS',
  'ENFORCEMENT COMMUNICATION ': 'EnforcementCommunication',
  'PROTECTION STAFF': 'ProtectionStaff',
  'LDWF REGIONAL OFFICES ': 'LdwfRegionalOffices',
  'LICENSING STAFF': 'LicensingStaff',
  'LICENSING MANAGEMENT ': 'LicensingManagement',
  'LDWF SUPERUSER ': 'LdwfSuperuser',
  HELPDESK: 'Helpdesk',
  ADMINISTRATOR: 'Administrator',
  S3_SUPERUSER: 'S3Superuser',
  CLERK: 'Clerk',
  MANAGER: 'Manager',
  'ENFORCEMENT MANAGER': 'EnforcementManager',
  'EVENTS ADMIN': 'EventsAdmin',
  'EVENTS AREA COORDINATOR': 'EventsAreaCoordinator',
  INSTRUCTOR: 'Instructor',
  'TD-HARVEST': 'TdHarvest',
  'Lottery Admin': 'LotteryAdmin',
  'Lottery Admin &POS  Manager': 'LotteryAdminPosManager',
  'S3-Accounting': 'S3Accounting',
  'ALLIGATOR CUSTOMER VIEW': 'AlligatorCustomerView',
  'WILDLIFE - MELISSA COLLINS': 'WildlifeMelissaCollins',
  User: 'User',
  ServiceAccount: 'ServiceAccount',
} as const;

export type StateCode = keyof typeof StateCode;
export const StateCode = {
  LOUISIANA: 'LA',
  ALASKA: 'AK',
  ALABAMA: 'AL',
  ARKANSAS: 'AR',
  ARIZONA: 'AZ',
  CALIFORNIA: 'CA',
  COLORADO: 'CO',
  CONNECTICUT: 'CT',
  'DISTRICT OF COLUMBIA': 'DC',
  DELAWARE: 'DE',
  FLORIDA: 'FL',
  GEORGIA: 'GA',
  HAWAII: 'HI',
  IOWA: 'IA',
  IDAHO: 'ID',
  ILLINOIS: 'IL',
  INDIANA: 'IN',
  KANSAS: 'KS',
  KENTUCKY: 'KY',
  MASSACHUSETTS: 'MA',
  MARYLAND: 'MD',
  MAINE: 'ME',
  MICHIGAN: 'MI',
  MINNESOTA: 'MN',
  MISSOURI: 'MO',
  MISSISSIPPI: 'MS',
  MONTANA: 'MT',
  'NORTH CAROLINA': 'NC',
  'NORTH DAKOTA': 'ND',
  NEBRASKA: 'NE',
  'NEW HAMPSHIRE': 'NH',
  'NEW JERSEY': 'NJ',
  'NEW MEXICO': 'NM',
  NEVADA: 'NV',
  'NEW YORK': 'NY',
  OHIO: 'OH',
  OKLAHOMA: 'OK',
  OREGON: 'OR',
  PENNSYLVANIA: 'PA',
  'RHODE ISLAND': 'RI',
  'SOUTH CAROLINA': 'SC',
  'SOUTH DAKOTA': 'SD',
  TENNESSEE: 'TN',
  TEXAS: 'TX',
  UTAH: 'UT',
  VIRGINIA: 'VA',
  VERMONT: 'VT',
  WASHINGTON: 'WA',
  WISCONSIN: 'WI',
  'WEST VIRGINIA': 'WV',
  WYOMING: 'WY',
  OTHER: 'OT',
  'PUERTO RICO': 'PR',
} as const;

export type TenderType = keyof typeof TenderType;
export const TenderType = {
  Cash: 'Cash',
  'Credit Card': 'CreditCard',
  Check: 'Check',
  'No Tender': 'NoTender',
  Other: 'Other',
} as const;

export type TransactionStatus = keyof typeof TransactionStatus;
export const TransactionStatus = {
  ACTIVE: 'Active',
  EXPIRED: 'Expired',
  VOID: 'Void',
  SUPERSEDED: 'Superseded',
  'LDWF VOID': 'LdwfVoid',
  INVALIDATED: 'Invalidated',
  CHARGED: 'Charged',
  RETURNED: 'Returned',
  REVOKED: 'Revoked',
} as const;

export type VendorReportNames = keyof typeof VendorReportNames;
export const VendorReportNames = {
  'VENDOR SALES': 'VENDORSALES',
  'CLERK SALES': 'CLERKSALES',
  'MY SALES': 'MYSALES',
  'VOID SALES': 'VOIDSALES',
} as const;

export type StatusCodeType = keyof typeof StatusCodeType;
export const StatusCodeType = {
  Customer: 'Customer',
  Staff: 'Staff',
  'Equipment Item': 'EquipmentItem',
  'Event Status': 'EventStatus',
  License: 'License',
  JournalDistributions: 'JournalDistributions',
  Journal: 'Journal',
  'ACH processing': 'AchProcessing',
  Inventory: 'Inventory',
  Issues: 'Issues',
  SalesTicketGroup: 'SalesTicketGroup',
  LSA: 'LSA',
  Statement: 'Statement',
  'Event Location': 'EventLocation',
} as const;

export type AntlerCode = keyof typeof AntlerCode;
export const AntlerCode = {
  ANTLERED: 'Antlered',
  ANTLERLESS: 'Antlerless',
} as const;

export type EthnicCode = keyof typeof EthnicCode;
export const EthnicCode = {
  OTHER: 'Other',
  'NATIVE AMERICAN': 'NativeAmerican',
  ASIAN: 'Asian',
  HISPANIC: 'Hispanic',
  BLACK: 'Black',
  WHITE: 'White',
} as const;

export type GenderCode = keyof typeof GenderCode;
export const GenderCode = {
  MALE: 'Male',
  FEMALE: 'Female',
  UNKNOWN: 'Unknown',
} as const;

export type HarvestSpecies = keyof typeof HarvestSpecies;
export const HarvestSpecies = {Deer: 'Deer', Turkey: 'Turkey'} as const;

export type LandTypeCode = keyof typeof LandTypeCode;
export const LandTypeCode = {
  'PUBLIC LAND': 'PublicLand',
  'PRIVATE LAND': 'PrivateLand',
  DMAP: 'DMap',
  UNKNOWN: 'Unknown',
} as const;

export type PchpClassType = keyof typeof PchpClassType;
export const PchpClassType = {
  'Class I: Wheelchair Bound (Permanent)': 'ClassI',
  'Class II: Mobility Impaired (Permanent)': 'ClassII',
  'Class II: Mobility Impaired (Temporary) (Disability must last for at least 1 year and the patient is expected to recover)':
    'TemporaryClassII',
  'Class III: Upper Extremity Amputee (Permanent)': 'ClassIII',
  'Class IV: Visually Impaired (Permanent)': 'ClassIV',
} as const;

export type ResidencyCode = keyof typeof ResidencyCode;
export const ResidencyCode = {
  RESIDENT: 'RESIDENT',
  'NON-RESIDENT': 'NONRESIDENT',
  BOTH: 'BOTH',
} as const;

export type SocialSecurityNumberStatus = keyof typeof SocialSecurityNumberStatus;
export const SocialSecurityNumberStatus = {
  EXEMPT: 'Exempt',
  'NON-EXEMPT': 'NonExempt',
} as const;

export type SourceIndicator = keyof typeof SourceIndicator;
export const SourceIndicator = {
  Internet: 'Internet',
  Vendor: 'Vendor',
  Import: 'Import',
  WebPOS: 'WebPOS',
  'Control Center': 'ControlCenter',
  Mobile: 'Mobile',
  Student: 'Student',
  IVR: 'IVR',
  Kalkomey: 'Kalkomey',
  BoatUS: 'BoatUS',
  Commercial: 'Commercial',
  'VMS IMPORT': 'VmsImport',
  'EDUCATION API': 'EducationApi',
  eCitation: 'eCitation',
} as const;

export type SuffixCode = keyof typeof SuffixCode;
export const SuffixCode = {
  JR: 'Jr',
  SR: 'Sr',
  I: 'I',
  II: 'II',
  III: 'III',
  IV: 'IV',
  V: 'V',
  VI: 'VI',
  VII: 'VII',
  VIII: 'VIII',
  IX: 'IX',
  X: 'X',
} as const;

export type TurkeyMaturityCode = keyof typeof TurkeyMaturityCode;
export const TurkeyMaturityCode = {
  ADULT: 'Adult',
  'JUVENILE (JAKE)': 'Juvenile',
} as const;

export type ValidationMethod = keyof typeof ValidationMethod;
export const ValidationMethod = {
  'Admin Portal': 'AdminPortal',
  'Customer Portal': 'CustomerPortal',
  'Text To Tag': 'TextToTag',
  IVR: 'IVR',
  'Mobile App': 'MobileApp',
} as const;
