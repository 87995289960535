import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import '@reach/skip-nav/styles.css';
import '@reach/tooltip/styles.css';
import 'semantic-ui-less/semantic.less';
import 'typeface-hind-madurai';
import 'typeface-roboto';
import './analytics/appinsights';
import './analytics/sentry';
import './api/generated/config';
import './dev-tools';
import styled from '@emotion/styled/macro';
import React, {Suspense} from 'react';
import ReactDOM from 'react-dom';
import {Route, BrowserRouter as Router, Switch} from 'react-router-dom';
import {ToastProvider} from 'react-toast-notifications';
import AnalyticsPageTracker from './analytics/AnalyticsPageTracker';
import AnalyticsUserTracker from './analytics/AnalyticsUserTracker';
import {ChangeExpiredPassword} from './auth/change-expired-password';
import {ConfirmAccount} from './auth/confirm-account';
import {ForgotPassword} from './auth/forgot-password';
import {OneTimeLoginLandingPage} from './auth/one-time-login-landing-page';
import {OneTimeLoginLandingPageAcceptLottery} from './auth/one-time-login-landing-page-accept-lottery';
import {OneTimeLoginLandingPageProductCatalogWrapper} from './auth/one-time-login-landing-page-product-catalog';
import {OneTimeLoginLandingPageProductCatalogTagsWrapper} from './auth/one-time-login-landing-page-product-catalog-tags';
import {ResetPassword} from './auth/reset-password';
import {AuthProvider} from './auth/use-auth';
import {CookieMonitor} from './cookie-monitor';
import {LoginCookieProvider} from './cookie-provider';
import {CustomerApp} from './customer-app';
import {BoatRegistrationConfirmation} from './internet-sales-portal/boat-renewal/boat-registration-confirmation';
import {BoatRenewalLookup} from './internet-sales-portal/boat-renewal/boat-renewal-lookup';
import {BoatRegistrationPaymentConfirmation} from './internet-sales-portal/boat-renewal/boat-renewal-payment-confirmation';
import {BoatRenewalPaymentReceipt} from './internet-sales-portal/boat-renewal/boat-renewal-payment-receipt';
import {CommercialLicenseListing} from './internet-sales-portal/commercial-license-renewal/commercial-license-listing';
import {CommercialLicenseLookup} from './internet-sales-portal/commercial-license-renewal/commercial-license-lookup';
import {CommercialLicenseRenewalPaymentConfirmation} from './internet-sales-portal/commercial-license-renewal/commercial-license-payment-confirmation';
import {CommercialLicenseRenewalPaymentReceipt} from './internet-sales-portal/commercial-license-renewal/commercial-license-payment-receipt';
import {EventDetails} from './internet-sales-portal/events/event-details';
import {IspEventListing} from './internet-sales-portal/events/event-listing';
import {OnlineCourseDetail} from './internet-sales-portal/online-course/online-course-detail';
import {OnlineCourseWrapper} from './internet-sales-portal/online-course/online-course-wrapper';
import {customerRoutes} from './internet-sales-portal/routes/config';
import {SalesLandingPage} from './internet-sales-portal/sales-landing';
import {routes} from './routes/config';
import {IspVendorPageWrapper} from './shared/wrappers/isp-vendor-page-wrapper';
import {GlobalStyles} from './styles';
import {ThemeProvider} from './theme/use-theme';
import {NotificationService} from './utils/notification-service';
import {VendorApp} from './vendor-app';
import {HelpPage} from './vendor-portal/help-page';

const AdminApp = React.lazy(() => import('./admin-app'));

export const ToastContainer = styled.div`
  position: fixed;
  top: 20px;
  right: 8px;
  max-height: 100%;
  z-index: 10001;
  box-sizing: border-box;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 8px;
`;

// eslint-disable-next-line react-perf/jsx-no-new-object-as-prop
const ToastComponents = {ToastContainer};

ReactDOM.render(
  <>
    <LoginCookieProvider>
      <CookieMonitor />
      <ToastProvider components={ToastComponents}>
        <ThemeProvider>
          <NotificationService />
          <GlobalStyles />
          <Suspense fallback={null}>
            <Router>
              <>
                <AnalyticsPageTracker />
                <Switch>
                  <Route path={routes.salesLanding} exact>
                    <IspVendorPageWrapper paddingBottom="50px">
                      <SalesLandingPage />
                    </IspVendorPageWrapper>
                  </Route>
                  <Route path={routes.resources.vendorHelp.root} exact>
                    <HelpPage />
                  </Route>
                  <Route path={routes.eventDetails} exact>
                    <IspVendorPageWrapper>
                      <EventDetails />
                    </IspVendorPageWrapper>
                  </Route>
                  <Route path={routes.confirmEmail}>
                    <ConfirmAccount />
                  </Route>
                  <Route path={routes.forgotPassword}>
                    <ForgotPassword />
                  </Route>
                  <Route path={routes.resetPassword}>
                    <ResetPassword />
                  </Route>
                  <Route path={routes.changeExpiredPassword}>
                    <ChangeExpiredPassword />
                  </Route>
                  <Route path={routes.oneTimeLoginLandingPageDashboard}>
                    <OneTimeLoginLandingPage path={customerRoutes.dashboard} />
                  </Route>
                  <Route path={routes.oneTimeLoginLandingPageTagging}>
                    <OneTimeLoginLandingPage
                      path={customerRoutes.validateTags}
                    />
                  </Route>
                  <Route
                    path={routes.oneTimeLoginLandingPageEmailProductCatalog}
                  >
                    <OneTimeLoginLandingPage
                      path={customerRoutes.licensesAndPermits}
                    />
                  </Route>
                  <Route path={routes.oneTimeLoginLandingPageActiveLicenses}>
                    <OneTimeLoginLandingPage
                      path={customerRoutes.customerlicensesAndPermits}
                    />
                  </Route>
                  <Route path={routes.oneTimeLoginLandingPageLotteries}>
                    <OneTimeLoginLandingPageAcceptLottery />
                  </Route>
                  <Route
                    path={routes.oneTimeLoginLandingPageProductCatalogTags}
                    exact
                  >
                    <OneTimeLoginLandingPageProductCatalogTagsWrapper />
                  </Route>
                  <Route
                    path={routes.oneTimeLoginLandingPageProductCatalog}
                    exact
                  >
                    <OneTimeLoginLandingPageProductCatalogWrapper />
                  </Route>
                  <Route path={routes.oneTimeLoginLandingPageAutoRenewProducts}>
                    <OneTimeLoginLandingPage
                      path={customerRoutes.autoRenewManagement.customerProducts}
                    />
                  </Route>
                  <Route
                    path={routes.oneTimeLoginLandingPageAutoRenewPaymentMethods}
                  >
                    <OneTimeLoginLandingPage
                      path={customerRoutes.autoRenewManagement.paymentMethod}
                    />
                  </Route>
                  <Route path={routes.events} exact>
                    <IspVendorPageWrapper>
                      <IspEventListing />
                    </IspVendorPageWrapper>
                  </Route>
                  <Route path={routes.boatRenewal.boatRenewalLookup} exact>
                    <IspVendorPageWrapper>
                      <BoatRenewalLookup />
                    </IspVendorPageWrapper>
                  </Route>
                  <Route
                    path={routes.boatRenewal.boatRegistrationConfirmation}
                    exact
                  >
                    <IspVendorPageWrapper>
                      <BoatRegistrationConfirmation />
                    </IspVendorPageWrapper>
                  </Route>
                  <Route
                    path={
                      routes.boatRenewal.boatRegistrationPaymentConfirmation
                    }
                    exact
                  >
                    <IspVendorPageWrapper>
                      <BoatRegistrationPaymentConfirmation />
                    </IspVendorPageWrapper>
                  </Route>
                  <Route
                    path={routes.boatRenewal.boatRegistrationReceipt}
                    exact
                  >
                    <IspVendorPageWrapper>
                      <BoatRenewalPaymentReceipt />
                    </IspVendorPageWrapper>
                  </Route>
                  <Route
                    path={
                      routes.commercialLicenseRenewal.commercialLicenseLookup
                    }
                    exact
                  >
                    <IspVendorPageWrapper>
                      <CommercialLicenseLookup />
                    </IspVendorPageWrapper>
                  </Route>
                  <Route
                    path={
                      routes.commercialLicenseRenewal.commercialLicenseListing
                    }
                    exact
                  >
                    <IspVendorPageWrapper>
                      <CommercialLicenseListing />
                    </IspVendorPageWrapper>
                  </Route>
                  <Route
                    path={
                      routes.commercialLicenseRenewal
                        .commercialLicensePaymentConfirmation
                    }
                    exact
                  >
                    <IspVendorPageWrapper>
                      <CommercialLicenseRenewalPaymentConfirmation />
                    </IspVendorPageWrapper>
                  </Route>
                  <Route
                    path={
                      routes.commercialLicenseRenewal
                        .commercialLicensePaymentReceipt
                    }
                    exact
                  >
                    <IspVendorPageWrapper>
                      <CommercialLicenseRenewalPaymentReceipt />
                    </IspVendorPageWrapper>
                  </Route>
                  <Route path={customerRoutes.onlineCourseDetail} exact>
                    <IspVendorPageWrapper>
                      <OnlineCourseWrapper>
                        <OnlineCourseDetail />
                      </OnlineCourseWrapper>
                    </IspVendorPageWrapper>
                  </Route>
                  <Route>
                    <AuthProvider>
                      <AnalyticsUserTracker />
                      <Suspense fallback={null}>
                        <Switch>
                          <Route path={routes.admin.root}>
                            <AdminApp />
                          </Route>
                          <Route path={routes.vendor.root}>
                            <IspVendorPageWrapper>
                              <VendorApp />
                            </IspVendorPageWrapper>
                          </Route>
                          <Route path={routes.customer.root}>
                            <IspVendorPageWrapper>
                              <CustomerApp />
                            </IspVendorPageWrapper>
                          </Route>
                        </Switch>
                      </Suspense>
                    </AuthProvider>
                  </Route>
                </Switch>
              </>
            </Router>
          </Suspense>
        </ThemeProvider>
      </ToastProvider>
    </LoginCookieProvider>
  </>,

  document.getElementById('root')
);
