import React from 'react';
import {useHistory, useLocation} from 'react-router';
import {BoatRenewalDto, BoatRenewalService} from '../../api/generated';
import {
  dropdown,
  fieldConfig,
  input,
  masked,
  RawFieldConfig,
} from '../../forms/schema-utils';
import {Form} from '../../forms';
import {routes} from '../../routes/config';
import {StyledButton} from '../../styled-components/styled-buttons';
import {useMedia} from 'react-use';
import {MediaSizes} from '../../styles/breakpoints';
import {Flex} from '../../components/flex';
import {StyledPageContainer} from '../../styled-page-container';
import {isNullOrUndefined} from '../../utils/object-helpers';
import css from '@emotion/css';
import {cx} from 'emotion';
import {StateCode} from '../../api/generated/enums';
import {getEnumDropdownOptions} from '../../api/generated/utils';

type OmitKeys =
  | 'id'
  | 'amount'
  | 'combinedName'
  | 'firstName'
  | 'middleName'
  | 'suffix'
  | 'isDualOwner'
  | 'dateOfBirth'
  | 'lastName'
  | 'hullIdNumber'
  | 'additionalNotes'
  | 'isEscalated';

export const BoatRegistrationConfirmation = () => {
  const location = useLocation();
  const history = useHistory();
  const isMobile = useMedia(`(${MediaSizes.MobileMax})`);
  const registrationInfo = location?.state?.registration as BoatRenewalDto;

  const continueRenewalProcess = async (values: BoatRenewalDto) => {
    const verifyAddressResult = await BoatRenewalService.validateAddress({
      body: values,
    });

    if (verifyAddressResult.hasErrors) {
      return verifyAddressResult;
    }

    //updates location state to allow for back button to show changes
    var locationState = location.state;
    locationState.registration = values;

    history.replace({state: locationState});
    history.push(routes.boatRenewal.boatRegistrationPaymentConfirmation, {
      registration: values,
    });
  };

  if (isNullOrUndefined(registrationInfo)) {
    history.replace(routes.boatRenewal.boatRenewalLookup);
    return <></>;
  }

  const hasFirstName = !isNullOrUndefined(registrationInfo.firstName);
  const hasLastName = !isNullOrUndefined(registrationInfo.lastName);

  const nameToDisplay =
    hasFirstName || hasLastName
      ? `for ${registrationInfo.lastName ?? ''}${
          registrationInfo.lastName && registrationInfo.firstName ? ',' : ''
        } ${registrationInfo.firstName ?? ''}`
      : '';

  return (
    <StyledPageContainer title={`Boat Registration Renewal ${nameToDisplay}`}>
      <Flex.Row fill css={styles} justifyContent="justify-center">
        <Form
          initialValues={registrationInfo}
          className={cx(isMobile ? 'ui form mini' : '')}
          onSubmit={continueRenewalProcess}
          render={() => (
            <>
              <FormFields isMobile={isMobile} fields={fields} />
              <Flex.Row className="form-actions">
                <StyledButton
                  type="submit"
                  size={isMobile ? 'sm' : 'md'}
                  padded={!isMobile}
                  primary
                  content={'Save and Continue'}
                />
                <StyledButton
                  padded={!isMobile}
                  size={isMobile ? 'sm' : 'md'}
                  onClick={() =>
                    history.push(routes.boatRenewal.boatRenewalLookup)
                  }
                  content={'Cancel'}
                />
              </Flex.Row>
            </>
          )}
        />
      </Flex.Row>
    </StyledPageContainer>
  );
};

const fields = fieldConfig<Omit<BoatRenewalDto, OmitKeys>>({
  longBoatNumber: input({
    fieldLabel: 'Registration Number',
    fieldRequired: true,
    inputProps: {
      readOnly: true,
    },
  }),
  streetAddress: input({
    fieldLabel: 'Mailing Address',
    fieldRequired: true,
    inputProps: {
      maxLength: 100,
    },
  }),
  city: input({
    fieldLabel: 'City',
    fieldRequired: true,
    inputProps: {
      maxLength: 100,
    },
  }),
  state: dropdown({
    fieldLabel: 'State',
    fieldRequired: true,
    inputProps: {
      search: true,
      options: getEnumDropdownOptions(StateCode),
      placeholder: 'Select a State...',
      selection: true,
    },
  }),
  zipCode: masked({
    fieldLabel: 'ZIP Code',
    fieldRequired: true,
    inputProps: {
      type: 'number',
      options: {
        numericOnly: true,
        blocks: [5],
      },
    },
  }),
  phoneNumber: masked({
    fieldLabel: 'Phone Number',
    fieldRequired: true,
    inputProps: {
      type: 'number',
      options: {
        numericOnly: true,
        blocks: [10],
      },
    },
  }),
  emailAddress: input({
    fieldLabel: 'Email Address',
    fieldRequired: true,
    inputProps: {
      maxLength: 100,
    },
  }),
});

export const FormFields: React.FC<{
  fields: RawFieldConfig<Omit<BoatRenewalDto, OmitKeys>>;
  isMobile: boolean;
}> = ({fields, isMobile}) => {
  return (
    <div className={cx(isMobile ? 'single-column-form' : 'two-column-form')}>
      <Form.Section title="Mailing Address Confirmation">
        <Form.Row proportions={[1, 1]}>
          <Form.Input fieldConfig={fields.longBoatNumber} />
        </Form.Row>
        <Form.Row proportions={[1, 1]}>
          <Form.InputMasked fieldConfig={fields.phoneNumber} />
          <Form.Input fieldConfig={fields.emailAddress} />
        </Form.Row>
        <Form.Row proportions={[1, 1]}>
          <Form.Input fieldConfig={fields.streetAddress} />
          <Form.Input fieldConfig={fields.city} />
        </Form.Row>
        <Form.Row proportions={[1, 1]}>
          <Form.Dropdown fieldConfig={fields.state} />
          <Form.InputMasked fieldConfig={fields.zipCode} />
        </Form.Row>
      </Form.Section>
    </div>
  );
};

const styles = css`
  .two-column-form {
    .form-row {
      .form-field:nth-of-type(1) {
        padding-left: 0px !important;
      }
      .form-field:nth-of-type(2) {
        padding-right: 0px !important;
      }
    }
  }

  .single-column-form {
    .form-field {
      padding-left: 0px !important;
      padding-right: 0px !important;
    }
  }
`;
