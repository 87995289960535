import React, {useState} from 'react';
import 'react-chat-widget/lib/styles.css';
import * as FlexWebChat from '@twilio/flex-webchat-ui';
import {useAsync} from 'react-use';
import {CustomerCatalogService} from '../api/generated';
import {notifications} from '../utils/notification-service';
import {Actions} from '@twilio/flex-webchat-ui';
import {Env} from '../config/env-vars';
import {Theme} from '../theme';

export const ChatWidget = () => {
  const fetchCustomerInfo = useAsync(async () => {
    const fetchCustomer = async () => {
      const customerCatalogResponse = await CustomerCatalogService.get();

      if (customerCatalogResponse.hasErrors) {
        notifications.error(
          'Our chatbot was not able to retrieve your information'
        );
      }

      const catalogResult = customerCatalogResponse.result;

      return {
        customerInfo: catalogResult?.customerInformation,
      };
    };

    const customerAndProductInfo = await fetchCustomer();

    return {
      ...customerAndProductInfo,
    };
  });

  const customerInfo = fetchCustomerInfo.value?.customerInfo;
  const [state, setState] = useState<any>({});

  useAsync(async () => {
    var hasCustomerInfo = customerInfo != null;

    const cLDWFGrey = Theme.palette.grey600;
    const cLDWFGreen = Theme.palette.green700;

    var brandColor1 = cLDWFGrey;
    var brandColor2 = cLDWFGreen;
    var brandTextColor = Theme.palette.white1;

    var personalizedColors = {
      darkBlueBackground: cLDWFGreen,
      whiteText: Theme.palette.white1,
      entryPointBackground: cLDWFGreen,
      lighterBackground: Theme.palette.white2,
      primaryButtonBackground: Theme.palette.blue400,
      primaryButtonColor: Theme.palette.white1,
      secondaryButtonColor: Theme.palette.white1,
    };

    var brandMessageBubbleColors = function (bgColor) {
      return {
        Bubble: {
          background: bgColor,
          color: brandTextColor,
        },
        Avatar: {
          background: bgColor,
          color: brandTextColor,
        },
        Header: {
          color: brandTextColor,
        },
      };
    };

    var brandedColors = {
      Chat: {
        MessageListItem: {
          FromOthers: brandMessageBubbleColors(brandColor2),
          FromMe: brandMessageBubbleColors(brandColor1),
        },
        MessageInput: {
          Button: {
            background: brandColor1,
            color: brandTextColor,
          },
        },
        MessageCanvasTray: {
          Container: {
            background: personalizedColors.darkBlueBackground,
            color: personalizedColors.whiteText,
          },
        },
      },

      MainHeader: {
        Container: {
          background: personalizedColors.darkBlueBackground,
          color: personalizedColors.whiteText,
        },
        Logo: {
          fill: brandTextColor,
        },
      },

      EntryPoint: {
        Container: {
          background: personalizedColors.entryPointBackground,
          color: personalizedColors.whiteText,
        },
      },
      PreEngagementCanvas: {
        Container: {
          background: personalizedColors.lighterBackground,
        },

        Form: {
          SubmitButton: {
            background: personalizedColors.primaryButtonBackground,
            color: personalizedColors.whiteText,
          },
        },
      },
    };

    if (customerInfo) {
      const appConfig = {
        accountSid: Env.twilioSID,
        flexFlowSid: Env.twilioFlowSID,
        disableLocalStorage: true,
        colorTheme: {
          overrides: brandedColors,
        },
        context: {
          friendlyName: customerInfo?.firstName,
          locationOrigin: window.location.origin,
          userId: customerInfo?.sportsmanId,
          callType: 'LDWF - Customer',
        },
        startEngagementOnInit: hasCustomerInfo,
        componentProps: {
          MessageCanvasTray: {
            onButtonClick: () => Actions.invokeAction('RestartEngagement'),
          },
        },
        preEngagementConfig: {
          description: 'I am sure we can help',
          fields: [
            {
              label: 'What is your first name?',
              type: 'InputItem',
              attributes: {
                name: 'friendlyName',
                type: 'text',
                required: true,
              },
            },
            {
              label: 'What is your email?',
              type: 'InputItem',
              attributes: {
                name: 'email',
                type: 'email',
                placeholder: 'Enter your email',
                required: true,
                readOnly: false,
              },
            },
            {
              label: 'Type of chat?',
              type: 'SelectItem',
              attributes: {
                name: 'chatType',
                required: true,
                readOnly: false,
              },
              options: [
                {
                  value: 'account',
                  label: 'Account Help',
                  selected: false,
                },
                {
                  value: 'purchase',
                  label: 'License Purchase',
                  selected: true,
                },
                {
                  value: 'tag',
                  label: 'Tagging',
                  selected: false,
                },
              ],
            },
            {
              label: 'Give me a few keywords about your need?',
              type: 'TextareaItem',
              attributes: {
                name: 'question',
                type: 'text',
                placeholder: 'Type your keywords here',
                required: false,
                rows: 4,
              },
            },
          ],
          submitLabel: "Ok Let's Go!",
        },
      };

      FlexWebChat.MainHeader.defaultProps.imageUrl = Env.chatBotLogo;

      await FlexWebChat.Manager.create(appConfig)
        .then((manager) => {
          setState({manager});
          FlexWebChat.Actions.addListener('afterStartEngagement', (payload) => {
            const {question} = payload.formData;
            if (!question) return;
            const {channelSid} = manager.store.getState().flex.session;
            manager.chatClient
              .getChannelBySid(channelSid)
              .then((channel) => channel.sendMessage(question));
          });
          // Changing the Welcome message
          manager.strings.WelcomeMessage =
            "You have reached the LDWF Wildlife chat bot. If you need a live person say, 'I need a human'";
        })
        .catch((error) => setState({error}));
    }
  }, [customerInfo]);

  return (
    <>
      {state.manager == null ? null : (
        <div>
          <FlexWebChat.ContextProvider manager={state.manager}>
            <FlexWebChat.RootContainer />
          </FlexWebChat.ContextProvider>
        </div>
      )}
    </>
  );
};
